import { Component, OnInit } from '@angular/core';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  mobileNumber: string;
  otpCode: Array<string> = ['', '', '', '', '', ''];

  loginErr = "";
  otpErr = "";

  otpResent = false;
  otpResentMsg = "Otp has been Sent";

  loginForm = true;
  otpForm = false;

  isEnterPressed = false;

  year = (new Date()).getFullYear();

  constructor(public service: MasterService) { }

  ngOnInit() {
    if (localStorage.getItem("loginId") !== null && localStorage.getItem("authToken") !== null) {
      this.getLoginProfileById();
    }
  }

  //auth/otp/send?mobile=<mobile>
  loginOtp() {
    this.loginErr = '';

    this.service.postRaw(`auth/otp/send?mobile=${this.mobileNumber}`, null, this.service.headers()).subscribe((res) => {
      this.otpForm = true;
      this.loginForm = false;

      setTimeout(() => {
        let index = 0;
        document.getElementById(`otpCode[${index}]`).focus();
      }, 800)

    },
      error => {
        let _json = JSON.parse(error._body);
        this.loginErr = _json.message;

        this.otpForm = false;
      })
  }

  resentOtp() {
    this.otpResent = true;
    this.loginOtp();

    setTimeout(() => {
      this.otpResent = false;
    }, 4000)
  }

  //quote-api/services/auth/validateOtp?mobileNumber=<mobileNumber>&OTP=<otp>
  /*
    "{
      ""authToken"": ""b6425f64d6231dee681098dcfedfdcce"",
      ""loginId"": 2
    }"
  */
  verifyOtp() {
    let otpCode = this.otpCode.join('');
    this.service.post(`auth/validateOtp?username=${this.mobileNumber}&password=${otpCode}`, null, this.service.headers()).subscribe((res) => {

      this.service.get(`auth/user-access`,`username=${this.mobileNumber}`, this.service.headers()).subscribe((userAccessRes) => {

        const loginId = userAccessRes.find(e => e.appName == 'QuoteApp');
        //const loginId = res.appsList[0];

        localStorage.setItem("loginId", loginId.userUniqueId);
        localStorage.setItem("authToken", res.token);

        setTimeout(() => {
          this.getLoginProfileById();
          this.isEnterPressed = false;
        }, 500)


      })

    },
      error => {
        this.isEnterPressed = false;
        let _json = JSON.parse(error._body);
        this.otpErr = _json.message;
      })
  }

  onKeyOtp(index, $event) {
    try {
      if ($event.key == "Enter") {
        $event.preventDefault();
        if (!this.isEnterPressed) { this.isEnterPressed = true; this.verifyOtp() };

        return;
      }
      if ($event.key == "Tab") {
        $event.preventDefault();
        return;
      }
      if ($event.key == "Backspace") {
        index = (index > 0) ? (index - 1) : 0;
        document.getElementById(`otpCode[${index}]`).focus();
        return;
      }
      if (index >= 5) {
        $event.preventDefault();
        return
      }
      document.getElementById(`otpCode[${index + 1}]`).focus()
    } catch (error) {

    }
  }

  //  quote-api/services/LoginProfile/byRole/{role}
  getLoginProfileById() {
    if (localStorage.getItem("loginId") == null && localStorage.getItem("authToken") == null) {
      return;
    }
    this.service.get(`quote-api/services/LoginProfile/${localStorage.getItem("loginId")}`, ``, this.service.authHeaders()).subscribe((res) => {
      this.service.o.l = res;
      localStorage.setItem("role", res.role);
      if (res.role === this.service.ROLES.ADMIN || res.role === this.service.ROLES.SUPER_USER) {
        this.service._router().navigateByUrl('/agent/school/view');
        return;
      }
      if (res.role === this.service.ROLES.AGENT) {
        this.service._router().navigateByUrl('/agent/school/view');
        return;
      } else {
        this.service._router().navigateByUrl('/agent/summary/' + res.schoolProfile.schoolId);
      }
    },
      error => {

      })
  }

}
