import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/pages/login/login.component';
import { AuthService as AuthGuard } from './services/auth.service';

const routes: Routes = [
  { 
    path: '', 
    //component: LoginComponent,
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { 
    path: 'login', 
    component: LoginComponent,
  },
  { 
    path: 'agent', 
    loadChildren: () => import('../app/component/pages/agent-layout/agent-layout.module').then(m => m.AgentLayoutModule),
    canActivate: [AuthGuard] 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
