import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { Http, Headers, Response } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { throwError } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

declare function modalhide(id);
declare function modalshow(id);

@Injectable({
  providedIn: 'root'
})
export class MasterService {


  private headerObj: object;

  public readonly ROLES = {
    ADMIN: "ADMIN",
    SUPER_USER: "SUPER_USER",
    AGENT: "AGENT",
  }

  public o: any = {
    l: {}, // login
    a: [], // agent profile
    aReplicate: [],
    ss: {
      p: {},
      l: [],
      as: {},
      modal: {
        selectedTextBook: {},
        selectedTextBookIndex: -1,
        selectedNoteBook: {},
        selectedNoteBookIndex: -1,
        selectedStationery: {},
        selectedStationeryIndex: -1,
        t: [],
        n: {
          list: [],
          obj: {}
        },
        s: []
      }
    },
    akt: [],
    akn: [],
    aks: []
  }

  public loader = {
    show: () => {
      document.getElementById("postLoader").style.display = "flex";
    },
    hide: () => {
      document.getElementById("postLoader").style.display = "none";
    }
  }

  /**
   * 
   * @param http 
   * @param httpClient 
   */
  constructor(
    public http: Http,
    public httpClient: HttpClient,
    public Router: Router,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
  ) {

    this.resourceget().subscribe((res) => {
      environment.url = res.url || environment.url;
      console.log(environment.url);
    })
  }

  handleInputNumber() {
    var inputBox = document.querySelector("input[type=number]");

    var invalidChars = [
      "-",
      "+",
      "e",
    ];

    inputBox.addEventListener("keydown", (e: KeyboardEvent) => {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  }

  handleError(error) {
    let _json = JSON.parse(error._body);
    let err = _json.errormessage;

    this.errorM(err);

    document.getElementById("postLoader").style.display = "none";
    if (error.status == 403) {
      localStorage.clear();
      this.Router.navigateByUrl('login');
    }
    console.log(error);
    return throwError(error);
  }

  handleSuccess(res) {
    console.log(res);
    this.loader.hide();
    return res;
  }

  resourceget(resourceName = 'resource.json') {

    return this.http.get("assets/" + resourceName, {}).
      pipe(map((res: Response) => { return this.handleSuccess(res.json()) })).pipe(catchError(err => this.handleError(err)));
  }

  /**
   * 
   * @param scriptName 
   * @param param 
   * @param headers 
   */
  get(scriptName: string, param: string, headers: object, url: String = environment.url) {
    this.loader.show();
    this.headerObj = {
      headers: new Headers(headers),
    };
    return this.http.get(url + scriptName + "?" + param, this.headerObj).
      pipe(map((res: Response) => { return this.handleSuccess(res.json()) })).pipe(catchError(err => this.handleError(err)));
  }


  /**
   * 
   * @param scriptName 
   * @param param 
   * @param headers 
   */
  getRaw(scriptName: string, param: string, headers: object, url: String = environment.url) {
    this.loader.show();
    this.headerObj = {
      headers: new Headers(headers),
    };
    return this.http.get(url + scriptName + "?" + param, this.headerObj).
      pipe(map((res: Response) => { return this.handleSuccess(res) })).pipe(catchError(err => this.handleError(err)));
  }

  /**
   * 
   * @param scriptName 
   * @param body 
   * @param headers 
   */
  post(scriptName: string, body: any, headers: object, url: String = environment.url) {
    this.loader.show();
    this.headerObj = {
      headers: new Headers(headers),
    };
    return this.http.post(url + scriptName, body, this.headerObj).
      pipe(map((res: Response) => { return this.handleSuccess(res.json()) })).pipe(catchError(err => this.handleError(err)));
  }

  /**
   * 
   * @param scriptName 
   * @param body 
   * @param headers 
   */
  put(scriptName: string, body: object, headers: object, url: String = environment.url) {
    this.loader.show();
    this.headerObj = {
      headers: new Headers(headers),
    };
    return this.http.put(url + scriptName, body, this.headerObj).
      pipe(map((res: Response) => { return this.handleSuccess(res.json()) })).pipe(catchError(err => this.handleError(err)));
  }

  /**
   * 
   * @param scriptName 
   * @param body 
   * @param headers 
   */
  delete(scriptName: string, param: string, headers: object, url: String = environment.url) {
    this.loader.show();
    this.headerObj = {
      headers: new Headers(headers),
    };
    return this.http.delete(url + scriptName + "?" + param, this.headerObj).
      pipe(map((res: Response) => { return this.handleSuccess(res) })).pipe(catchError(err => this.handleError(err)));
  }

  /**
   * 
   * @param scriptName 
   * @param body 
   * @param headers 
   */
  postRaw(scriptName: string, body: object, headers: object, url: String = environment.url) {
    this.loader.show();
    this.headerObj = {
      headers: new Headers(headers),
    };
    return this.http.post(url + scriptName, body, this.headerObj).
      pipe(map((res: Response) => { return this.handleSuccess(res) })).pipe(catchError(err => this.handleError(err)));
  }

  /**
   * 
   * @param scriptName 
   * @param body 
   * @param headers 
   */
  putRaw(scriptName: string, body: object, headers: object, url: String = environment.url) {
    this.loader.show();
    this.headerObj = {
      headers: new Headers(headers),
    };
    return this.http.put(environment.url + scriptName, body, this.headerObj).
      pipe(map((res: Response) => { return this.handleSuccess(res) })).pipe(catchError(err => this.handleError(err)));
  }

  saveExcel(scriptName) {
    var xhr = new XMLHttpRequest();
    xhr.open('GET', environment.url + scriptName, true);
    xhr.responseType = 'blob';

    xhr.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    xhr.setRequestHeader('Authorization', localStorage.getItem("authToken"));
    xhr.onload = function (e) {

      if (this.status == 200) {
        var blob = new Blob([this.response], { type: 'application/vnd.ms-excel' });
        var downloadUrl = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = "data.xls";
        document.body.appendChild(a);
        a.click();
      } else {
        alert('Unable to download excel.')
      }
    };
    xhr.send();
  }

  logout() {
    localStorage.clear();
    this._router().navigateByUrl('/login');
  }

  _router() {
    return this.Router;
  }

  filterIt(arr, searchKey) {
    //console.log(arr);
    return arr.filter(function (obj) {
      return Object.keys(obj).some(function (key) {
        //console.log(obj[key]);
        if (obj[key] != null) {
          let _obj = obj[key].toString().toLowerCase();
          if (_obj.includes(searchKey)) {
            return obj[key];
          }
        }
      })
    });
  }

  /**
   * 
   */
  headers(): object {
    return {
      'Content-Type': 'application/json',
      'APP_NAME': 'QuoteApp',
      'COMPANY_CODE': 'AKSHAR',
      'Authorization': `${localStorage.getItem("authToken")}`
    }
  }


  headersNew(): object {
    return this.headers();
  }

  authHeaders(): object {
    return {
      'Content-Type': 'application/json',
      'APP_NAME': 'QuoteApp',
      'COMPANY_CODE': 'AKSHAR',
      'Authorization': `${localStorage.getItem("authToken")}`
    }
  }

  authHeadersForm(): object {
    return {
      'Authorization': `${localStorage.getItem("authToken")}`
    }
  }

  uppercase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  close() {
    function success() {
      document.querySelector('.app-success').classList.remove('on');
      document.querySelector('.app-success').classList.add('hide');
    }
    function error() {
      document.querySelector('.app-error').classList.remove('on');
      document.querySelector('.app-error').classList.add('hide');
    }
    function info() {
      document.querySelector('.app-info').classList.remove('on');
      document.querySelector('.app-info').classList.add('hide');
    }

    return {
      success: success,
      error: error,
      info: info
    }
  }

  successM(message) {
    document.querySelector('.success-msg').innerHTML = message;
    document.querySelector('.app-success').classList.add('on');
    document.querySelector('.app-success').classList.remove('hide');
    setTimeout(() => { this.close().success() }, 3000);
  }

  errorM(message) {
    document.querySelector('.error-msg').innerHTML = message;
    document.querySelector('.app-error').classList.add('on');
    document.querySelector('.app-error').classList.remove('hide');
    setTimeout(() => { this.close().error() }, 3000)
  }

  infoM(message) {
    document.querySelector('.info-msg').innerHTML = message;
    document.querySelector('.app-info').classList.add('on');
    document.querySelector('.app-info').classList.remove('hide');
    setTimeout(() => { this.close().info() }, 3000)
  }

  customSelect(evt, className) {

    var list = (<HTMLElement>document.querySelector('.' + className));
    list.style.display = "block";
    evt.value = "";
    (<HTMLElement>list.querySelector('li button')).style.display = "none";

  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  searchValue($this, className) {
    console.log($this.value);
    var list = document.querySelector('.' + className);
    list.querySelectorAll('li.allow').forEach(function (el) {
      var vii = el.querySelector('a').innerHTML.trim().toLowerCase().indexOf($this.value.trim().toLowerCase());
      if (vii >= 0) {
        el.classList.add('display-block');
        el.classList.remove('display-none');
        (<HTMLElement>list.querySelector('li button')).style.display = "none";
        (<HTMLElement>document.getElementsByClassName(className + 'Scroll')[0]).style.display = "block";
      } else {
        el.classList.add('display-none');
        el.classList.remove('display-block');
        (<HTMLElement>list.querySelector('li button')).style.display = "block";
        (<HTMLElement>document.getElementsByClassName(className + 'Scroll')[0]).style.display = "block";
      }

      if (list.querySelectorAll('.display-block').length == 0) {
        (<HTMLElement>list.querySelector('li button')).style.display = "block";
        (<HTMLElement>document.getElementsByClassName(className + 'Scroll')[0]).style.display = "block";
      }

    });
    if (list.querySelectorAll('li.allow').length == 0) {
      (<HTMLElement>list.querySelector('li button')).style.display = "block";
      (<HTMLElement>document.getElementsByClassName(className + 'Scroll')[0]).style.display = "block";
    }
    if ($this.value == "") {
      (<HTMLElement>list.querySelector('li button')).style.display = "none";

    }
  }

  dateSort(a, b) {
    var dateA = new Date(a.date).getTime();
    var dateB = new Date(b.date).getTime();
    return dateA > dateB ? 1 : -1;
  }

  modalhide(id) {
    modalhide(id);
  }

  modalshow(id) {
    modalshow(id);
  }

  deepCopy(response) {
    return JSON.parse(JSON.stringify(response));
  }

  compareObject(obj1, obj2) {
    console.log(obj1);
    console.log(obj2);
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
      this.infoM("No Update");
      return true;
    } else {
      return false;
    }
  }
}
