import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements CanActivate {
  
  constructor(public auth: AuthService, public router: Router) {}

  isAuth(){
    if(localStorage.getItem("authToken")!==null && localStorage.getItem("loginId")!==null){
      return true;
    }

    return false;
  }

  canActivate(route: import("@angular/router").ActivatedRouteSnapshot, state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
    if(!this.isAuth()){
      this.router.navigate(['login']);
      return false;
    }
    return true;
  } 
}
